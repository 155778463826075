<template>
    <header>
        <div class="w-full flex flex-row justify-around items-center gap-4 md:gap-0">
            <div class="hamburger">
                <div class="hamburger-box" @click="showMobileMenu">
                    <div class="hamburger-inner" :class="[mobileMenuVisible ? 'is-active' : '']">
                        <span></span>
                    </div>
                </div>
            </div>

            <div>
                <figure>
                    <NuxtLink class="hidden md:block" @click="mobileMenuVisible = false" :to="localePath('/')">
                        <CoreLogo />
                    </NuxtLink>
                    <NuxtLink class="md:hidden" @click="mobileMenuVisible = false" :to="localePath('/')">
                      <CoreShortLogo />
                    </NuxtLink>
                </figure>
            </div>
            <div class="hidden md:flex md:flex-row justify-between gap-4">
                <div class="nav-item">
                    <NuxtLink class="text-meclee-black hover:text-meclee-blue" :to="localePath('/psychologists')">{{ t('meclee-ui.menu.main.psychologists') }}</NuxtLink>
                </div>
                <div class="nav-item">
                    <NuxtLink class="text-meclee-black hover:text-meclee-blue" :to="localePath('/blog')">{{ t('meclee-ui.menu.main.blog') }}</NuxtLink>
                </div>
                <div class="nav-item">
                    <NuxtLink class="text-meclee-black hover:text-meclee-blue" :to="localePath({path: '/', hash: '#faq'})">{{ t('meclee-ui.menu.main.faq') }}</NuxtLink>
                </div>
                <div class="nav-item">
                    <NuxtLink class="text-meclee-black hover:text-meclee-blue" :to="localePath('/for-psychologists')">{{ t('meclee-ui.menu.main.specialists') }}</NuxtLink>
                </div>
                <div class="nav-item">
                    <NuxtLink class="text-meclee-black hover:text-meclee-blue" :to="localePath('/business')">{{ t('meclee-ui.menu.main.business') }}</NuxtLink>
                </div>
            </div>
            <div class="flex justify-between items-center ml-auto lg:ml-0">
              <span class="mr-5 cursor-pointer text-slate-500 hover:text-meclee-blue font-medium"
                    @click="localeCurrencyModal = true"
              >
                {{ t(`locales.short.${locale}`) }}/{{ currency }}
              </span>
              <LoggedDropdown />
              <div class="hidden xl:flex">
                <NuxtLink class="button is-secondary" :to="localePath('/matching')" style="margin-left: 24px">
                    {{ t('meclee-ui.menu.questionnaire') }}
                </NuxtLink>
              </div>
            </div>
        </div>

        <Transition name="fade">
            <div id="mobile-menu" v-if="mobileMenuVisible">
                <div id="mobile-menu-container">
                    <div class="navigation mobile">
                        <div class="nav-item">
                            <NuxtLink @click="mobileMenuVisible = false" :to="localePath('/psychologists')">{{ t('meclee-ui.menu.main.psychologists') }}</NuxtLink>
                        </div>
                        <div class="nav-item">
                            <NuxtLink @click="mobileMenuVisible = false" :to="localePath('/blog')">{{ t('meclee-ui.menu.main.blog') }}</NuxtLink>
                        </div>
                        <div class="nav-item">
                            <NuxtLink @click="mobileMenuVisible = false" :to="localePath({path: '/', hash: '#faq'})">{{ t('meclee-ui.menu.main.faq') }}</NuxtLink>
                        </div>
                        <div class="nav-item">
                            <NuxtLink @click="mobileMenuVisible = false" :to="localePath('/for-psychologists')">{{ t('meclee-ui.menu.main.specialists') }}</NuxtLink>
                        </div>
                        <div class="nav-item">
                            <NuxtLink @click="mobileMenuVisible = false" :to="localePath('/business')">{{ t('meclee-ui.menu.main.business') }}</NuxtLink>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center; align-items: center" class="flex-col space-y-3">
                        <NuxtLink class="button is-secondary" @click="mobileMenuVisible = false" :to="localePath('/matching')" v-if="!isLogged">
                            {{ t('meclee-ui.menu.questionnaire') }}
                        </NuxtLink>
                    </div>

                </div>
            </div>
        </Transition>
    </header>

    <MosaicUiModalsLocaleCurrencySwitcher v-model="localeCurrencyModal" />
</template>
<script setup>
import LoggedDropdown from "./LoggedDropdown.vue";

const localePath = useLocalePath();
const { t, locale } = useI18n();
const { currency } = useCurrency(locale.value);
import { onMounted } from "vue";
import { useScrollLock } from '@vueuse/core';
const route = useRoute()
import { useLocalePath } from "#i18n";

import { useResizeObserver } from '@vueuse/core'

const locates = [
    {
        name: "RU",
        path: "ru"
    },
    {
        name: "UK",
        path: "uk"
    },
]

let isScrollLocked;
if (process.client) {
  isScrollLocked = useScrollLock(document.querySelector('html'));
} else {
  isScrollLocked = ref(false);
}

const config = useRuntimeConfig();
const {session} = useAuthSession();

const isLoggedIn = computed(() => session.value?.selectedProfile !== undefined);
const profileLabel = computed(() => session.value?.selectedProfile.label);
const cabinetHomePagePath = ref(config.public.auth.homePagesByProfile[session.value?.selectedProfile.type]);

const mobileMenuVisible = ref(false)
const userMenuVisible = ref(false)
const localeCurrencyModal = ref(false);

watchEffect(() => {
  if (mobileMenuVisible.value) {
    isScrollLocked.value = true;
  } else {
    isScrollLocked.value = false;
  }
})

const showMobileMenu = () => mobileMenuVisible.value = !mobileMenuVisible.value
const showUserMenu = () => userMenuVisible.value = !userMenuVisible.value

const setResizesValues = (val) => {
    if (val > 1020) {
        mobileMenuVisible.value = false
    }
}
onMounted(() => {
    setResizesValues(window.widthInner)
    useResizeObserver(document.querySelector("body"), (entries) => {
        setResizesValues(entries[0].contentRect.width)
    })
})
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .6s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
